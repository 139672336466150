'use client';

import { useSplits } from '@mentimeter/splitio';
import { useUser } from '@mentimeter/user';
import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import type { CustomerIo } from './types';

const CUSTOMER_IO_SITE_ID = process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID;
const CUSTOMER_IO_ORGANIZATION_ID =
  process.env.NEXT_PUBLIC_CUSTOMER_IO_ORGANIZATION_ID;

const SPLIT_ID = 'PM_CustomerIO_In_App_Messaging';

function convertDateStringtoUnixTime(date: Date): number {
  return Math.round(date.getTime() / 1000);
}

function useIdentifyCustomerIo(): { isReady: boolean } {
  const { [SPLIT_ID]: treatment } = useSplits([SPLIT_ID]);
  const { user } = useUser();
  const [identified, setIdentified] = useState(false);

  useEffect(() => {
    if (window._cio && treatment === 'on' && user && !identified) {
      window._cio.identify({
        id: user.region === 'us' ? `us${user.id}` : String(user.id),
        email: user.email,
        created_at: convertDateStringtoUnixTime(new Date(user.createdAt)),

        first_name: user.name,
      });

      setIdentified(true);
    }
  }, [user, identified, treatment]);

  const hasCredentials = !!(CUSTOMER_IO_SITE_ID || CUSTOMER_IO_ORGANIZATION_ID);

  return {
    isReady: treatment === 'on' && hasCredentials,
  };
}

/**
 * @see https://customer.io/docs/sdk/web/in-app/
 * @see https://customer.io/docs/in-app-getting-started/
 */
export function CustomerioScript() {
  const { isReady } = useIdentifyCustomerIo();

  if (!isReady) {
    return null;
  }

  return (
    <Script
      strategy="afterInteractive"
      id="customer-io-in-app-messaging"
      dangerouslySetInnerHTML={{
        /**
         * @see https://customer.io/docs/in-app-getting-started/#javascript-snippet
         * @see https://customer.io/docs/sdk/web/getting-started/#configure-the-sdk
         */
        __html: `
          var _cio = _cio || [];
          (function() {
              var a,b,c;a=function(f){return function(){_cio.push([f].
                  concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
                  "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
          var t = document.createElement('script'),
          s = document.getElementsByTagName('script')[0];
          t.async = true;
          t.id = 'cio-tracker';
          t.setAttribute('data-site-id', '${CUSTOMER_IO_SITE_ID}');
          t.setAttribute('data-use-array-params', 'true');
          t.setAttribute('data-in-app-org-id', '${CUSTOMER_IO_ORGANIZATION_ID}');
          t.setAttribute('data-use-in-app', 'true');
          t.setAttribute('data-auto-track-page', 'true');
          t.src = 'https://assets.customer.io/assets/track.js';
          s.parentNode.insertBefore(t, s);
          })();`,
      }}
    />
  );
}

export type { CustomerIo };
